import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  token:string;
  
  constructor( public navCtrl: NavController ) {
    this.token = localStorage.getItem('token');
  }

  ngOnInit() {
    if(this.token){
      this.navCtrl.navigateRoot('home/objetos');
    }else{
      this.navCtrl.navigateRoot('login');
    }
  }

}
