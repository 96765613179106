import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acc'
})
export class AccPipe implements PipeTransform {

  transform(value: any) {
      if( value == 1 ){
        return "Motor Encendido";
      }

      return "Motor Apagado";
  }

}
