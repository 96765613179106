import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expira'
})
export class ExpiraPipe implements PipeTransform {

  transform(value: any): any {
    let now = new Date().getTime();    
    if( parseFloat( value ) ){
        if( value < now ){
          let time = now - value;          
          return this.timeConversion(time, value);
        }else{
          return "";
        }
    }else{
        return "Sin Fecha de Reg.";
    }
  }

  getTIMESTAMP(timestamp:any) {
    let months:any = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    let date:any = new Date(timestamp);
    let year:any = date.getFullYear();
    let month:any = ("0"+(date.getMonth()+1)).substr(-2);
    let day = ("0"+date.getDate()).substr(-2);
    let hour = ("0"+date.getHours()).substr(-2);
    let minutes = ("0"+date.getMinutes()).substr(-2);
    let seconds = ("0"+date.getSeconds()).substr(-2);

    return months[month-1]+" "+day+", "+(year+1);
  }

  timeConversion(millisec:any, value:any) {	
      if( millisec ){				
          let seconds = (millisec / 1000).toFixed(1);
          var minutes = (millisec / (1000 * 60)).toFixed(1);
          var hours = (millisec / (1000 * 60 * 60)).toFixed(1);
          var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

          if (parseFloat(seconds) < 60) {
              return seconds + " seg";
          } else if (parseFloat(minutes) < 60) {
              return minutes + " min";
          } else if (parseFloat(hours) < 24) {
              return hours + " hrs";
          } else {
              return this.dias(days, value); 
          }
      }else{
          return 0;
      }
  }

  dias(dias:any, timestamp:any){
    
    if( dias < 365 ){
        let venc = 365 - dias;
        return `${this.getTIMESTAMP(timestamp)}, faltan ${venc.toFixed(1)} días`;
    }else{
        return  `${this.getTIMESTAMP(timestamp)} - VENCIDO`;
    }
  }


}
