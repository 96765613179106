import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiService } from './services/api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './interceptors/global.interceptor.service';
import { AccPipe } from './pipes/acc.pipe';
import { ExpiraPipe } from './pipes/expira.pipe';

@NgModule({
  declarations: [AppComponent, AccPipe, ExpiraPipe],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
              { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
              ApiService,
              {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptorService,
                multi:true
              }              
            ],
  bootstrap: [AppComponent],
})
export class AppModule {}
