import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor( public http: HttpClient ) { 
    
  }

  login(data:any){    
    console.log( data );
    return this.http.post(`${environment.server}/api/login`, data);
  }

  recuperaPass(datos:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(`${environment.server}/api/recupera-pass`,datos, httpOptions);
  }

  getObjetos( datos:any ){  
    return this.http.post(`${environment.server}/api/objetos`, datos);
  }

  getObjeto( datos:any ){  
    return this.http.post(`${environment.server}/api/objeto`,datos);
  }

  getUser(  datos:any){
    return this.http.post(`${environment.server}/api/user`,datos);
  }

  getComandos( datos:any ){
    return this.http.post(`${environment.server}/api/get-comandos`,datos);       
  }

  envioDeComando( datos:any ){
    return this.http.post(`${environment.server}/api/comando`,datos);
  }

  geocercas( postData:any ){
    return this.http.post(`${environment.server}/api/geocercas`,postData);
  }

  eliminaGeoPDI(postData:any){
    //id 1 y 2 geocerca 3 punto de interes y 4 ruta
    //nombre
    //tipo
    return this.http.post(`${environment.server}/api/eliminar-geocerca-pdi`,postData);
  }

  addGeocercaPDIRutas( postData:any ){
    return this.http.post(`${environment.server}/api/add-geopdiruta`,postData);
  }

  alertas(postData:any){
    return this.http.post(`${environment.server}/api/alertas-movil`,postData);
  }

  alertasNuevas(postData:any){
    return this.http.post(`${environment.server}/api/alertas-nuevas`,postData);
  }

  historialObjeto( postData:any ){
    return this.http.post(`${environment.server}/api/historial-objeto`,postData);
  }

  updateObjeto( postData:any ){
    return this.http.post(`${environment.server}/api/update-objeto`,postData);    
  }

  objetoId(postData:any){    
    return this.http.post(`${environment.server}/api/objeto-id`,postData);    
  }

  updateUser(postData:any){
    return this.http.post(`${environment.server}/api/update-user`,postData);       
  }

  updateToken(postData:any){
    return this.http.post(`${environment.server}/api/update-keypush`,postData);       
  }

}
