import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  loader:any;
  loading:any;

  constructor( public loadingController: LoadingController, 
               public alertController: AlertController,
               public _http: HttpClient, public router: Router, public navCtrl:NavController ) { }

  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>>{   
    

    const token: string = localStorage.getItem("token");

    let request = req;

    // this.showLoader();

    if( token ){
      
      const auth = request.clone({
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': token
        })      
      }); 
      
      // this.hideLoader();

      return next.handle(auth);

    }else{
      if( this.router.url != "login" ){
        this.navCtrl.navigateRoot("login");
      }
    }   

    // this.hideLoader();
    
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
        }
        return event;
      }),
      catchError((err:HttpErrorResponse)=>{
        // this.hideLoader();
        console.log( err );
        if( err.status == 401 ){
          this.alert( "No autorizado", err.error.message );
          localStorage.clear();
          this.navCtrl.navigateRoot("login");
        }
        
        return throwError( err );
      })
    );

  }

  async showLoader() {
    if (!this.loader) {
        this.loader = await this.loadingController.create({ message: 'Cargando...', duration: 2000 });
    }
    await this.loader.present();
  }

  async hideLoader() {
    if (this.loader) {
        await this.loader.dismiss();
        // this.loadingController.dismiss();
        this.loader = null;
    }
  }

  async alert( header:string, message: string ) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      buttons: [
        // {
        //   text: 'Cancelar',
        //   role: 'cancel',
        //   cssClass: 'secondary',
        //   handler: (blah) => {
        //     console.log('Confirm Cancel: blah');
        //   }
        // }, 
        {
          text: 'Aceptar',
          handler: () => {
            console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      // cssClass: 'my-custom-class',
      message: 'Cargando...',
      duration: 2000
    });
    await this.loading.present();

    const { role, data } = await this.loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

}
