import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [ 
  {
    path: '',
    redirectTo: 'home/objetos',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./pages/cuenta/cuenta.module').then( m => m.CuentaPageModule)
  },
  {
    path: 'recuperar',
    loadChildren: () => import('./pages/recuperar/recuperar.module').then( m => m.RecuperarPageModule)
  },
  {
    path: 'menu-objeto/:id',
    loadChildren: () => import('./pages/menu-objeto/menu-objeto.module').then( m => m.MenuObjetoPageModule)
  },
  {
    path: 'detalle-objeto/:id',
    loadChildren: () => import('./pages/detalle-objeto/detalle-objeto.module').then( m => m.DetalleObjetoPageModule)
  },
  {
    path: 'seguir-objeto/:id',
    loadChildren: () => import('./pages/seguir-objeto/seguir-objeto.module').then( m => m.SeguirObjetoPageModule)
  },
  {
    path: 'envio-comandos/:id',
    loadChildren: () => import('./pages/envio-comandos/envio-comandos.module').then( m => m.EnvioComandosPageModule)
  },
  {
    path: 'envio-comandos',
    loadChildren: () => import('./pages/envio-comandos/envio-comandos.module').then( m => m.EnvioComandosPageModule)
  },
  {
    path: 'geocercas/:id',
    loadChildren: () => import('./pages/geocercas/geocercas.module').then( m => m.GeocercasPageModule)
  },
  {
    path:"alertas/:id",
    loadChildren: () => import('./pages/home/alertas/alertas.module').then( m => m.AlertasPageModule )    
  },
  {
    path: 'geocercas-list/:id',
    loadChildren: () => import('./pages/geocercas-list/geocercas-list.module').then( m => m.GeocercasListPageModule)
  },
  {
    path: 'cuenta-update',
    loadChildren: () => import('./pages/cuenta-update/cuenta-update.module').then( m => m.CuentaUpdatePageModule)
  },
  {
    path: 'contrasena-update',
    loadChildren: () => import('./pages/contrasena-update/contrasena-update.module').then( m => m.ContrasenaUpdatePageModule)
  },
  {
    path: 'reportes/:id',
    loadChildren: () => import('./pages/reportes/reportes.module').then( m => m.ReportesPageModule)
  },
  {
    path: 'calendario',
    loadChildren: () => import('./pages/calendario/calendario.module').then( m => m.CalendarioPageModule)
  },
  {
    path: 'editar-objeto/:id',
    loadChildren: () => import('./pages/editar-objeto/editar-objeto.module').then( m => m.EditarObjetoPageModule)
  },
  {
    path: 'view-alert',
    loadChildren: () => import('./view-alert/view-alert.module').then( m => m.ViewAlertPageModule)
  },
  {
    path: 'reportes-tab',
    loadChildren: () => import('./reportes-tab/reportes-tab.module').then( m => m.ReportesTabPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
